import React, { useEffect, useState } from 'react';
import { shuffle } from 'lodash';
import ScrollbarVertical from '../../components/project-ui/ScrollbarVertical';
import { PageLeaderboardTeacherDownload } from '../../pages/leaderboard';
import { getRanking } from '../../utils/api';
import LeaderboardUser from '../../utils/models/LeaderboardUser';
import { useAuthContext } from '../../utils/providers/AuthProvider';
import "./ModalLeaderBoardV4.scss";
import "../../pages/dialog-teacher.scss";
import { AvatorIcons } from './ModalProfileAvatorSelectorV4';
import ModalReel from './ModalReel';
import { RootPortal } from '../../utils/providers/AppConfigProvider';
import BgRowAbs from "./BgRowAbs";
import { extendsLeaderboardUser, filterByLevel, LeaderboardUserEx, LEVELS } from '../constants';
interface ModalLeaderBoardV4Props {
  onClose?: () => void,
}

const LeaderboardUserItem = ({ user, index }: { user: LeaderboardUser, index: number }) => {
  return (
    <div className="modal-leader-board-student">
      <div className="modal-leader-board-student-index">{1 + index}</div>
      <div className="modal-leader-board-student-avator" data-uc={user.user_character}>
        {null !== user.user_character && !isNaN(parseInt(user.user_character)) && AvatorIcons[parseInt(user.user_character)]
          ? <img src={AvatorIcons[parseInt(user.user_character)]} alt={user.cname} />
          : <img src={AvatorIcons[0]} alt={user.cname} />
        }
        {/* {<img src={AvatorIcons[Math.round(1 + Math.random() * 30)]} alt={user.cname} />} */}
      </div>
      <div className="modal-leader-board-student-names">
        <span className="modal-leader-board-student-name">{user.cname} </span>
        <span className="modal-leader-board-student-seperator">-</span>
        <span className="modal-leader-board-student-school"> - {user.cSchoolName}</span>
        <span className="modal-leader-board-student-class-no"> ({user.classNo})</span>
      </div>
      {/* <div className="modal-leader-board-student-star"></div> */}
      {/* <div className="modal-leader-board-student-score">{parseInt(user.sum_star)} / {parseInt(user.sum_score)}</div> */}
      <div className="modal-leader-board-student-score">{parseInt(user.sum_score)}</div>
    </div>
  )
}

const ScrollList = ({ type, onDesc }: { type: 'all' | 'school', onDesc: () => void }) => {
  const [dataAll, setDataAll] = useState<LeaderboardUserEx[]>([]);
  const [data, setData] = useState<LeaderboardUserEx[]>([]);
  const [loaded, setLoaded] = useState(false);
  const { logged } = useAuthContext();
  const [level, setLevel] = useState<1 | 2 | 3 | 4>(1);

    const [data2, setData2] = useState<LeaderboardUser[]>([]);

  useEffect(() => {
    setLoaded(false);
    setDataAll([]);
    setData([]);
    setLevel(1);
    getRanking(type).then(r => {
      let temp = extendsLeaderboardUser(r);
      setDataAll(temp);
      setData(filterByLevel(temp, 1));
      setLoaded(true);
      setData2(r);
    });
  }, [type]);

  useEffect(() => {
    let temp = filterByLevel(dataAll, level);
    if (1 === level) {
      let chunks: { [score: number]: Array<LeaderboardUserEx> } = {};
      temp.forEach(x => {
        chunks[x.sumScore] = chunks[x.sumScore] ? chunks[x.sumScore] : [];
        chunks[x.sumScore].push(x);
      });
      let scores = Object.keys(chunks).sort((a, b) => b.localeCompare(a));
      let temp2: Array<LeaderboardUserEx> = [];
      scores.forEach(score => {
        temp2 = temp2.concat(shuffle(chunks[parseInt(score)]));
      })

      temp = temp2;
    } else {
      temp = shuffle(temp);
    }
    setData(temp);
  }, [dataAll, level]);

  const handleChangeLevel = (i: 1 | 2 | 3 | 4) => {
    setLevel(i);
  }

  if (!loaded) {
    return <>加載中...</>;
  }
  // console.log(logged);
  return (<ScrollbarVertical>
    <div className="modal-leader-board-list-levels">
      {LEVELS.map((LEVEL, index) => (
        <BgRowAbs onClick={() => handleChangeLevel((1 + index) as 1 | 2 | 3 | 4)} className={`${level === 1 + index ? 'active ' : ''}modal-leader-board-list-level-${1 + index} modal-leader-board-list-level`}>
          <div className="decoration"></div>
          {LEVEL.name ? LEVEL.name.split('').map((s, i) => <div className="char" key={i}>{s}</div>) : null}
          <div className="decoration"></div>
        </BgRowAbs>
      ))}
    </div>
    {0 === data.length ? <div className="modal-leader-board-list-levels-no_result">
      <h3>未有帳戶達到此排行榜的積分要求。請繼續努力﹗</h3>
      <a href="#" onClick={onDesc}>你可在此查看排行榜積分計算及規則</a>
    </div> : <div>
        {data.map((leaderboardUser, index) => <LeaderboardUserItem key={leaderboardUser.userID} user={leaderboardUser} index={index} />)}
      </div>}
    <RootPortal>
      {logged && 'Teacher' === logged.role && 'school' === type ? <PageLeaderboardTeacherDownload data={data2} /> : null}
    </RootPortal>
  </ScrollbarVertical>)
}

const ModalLeaderBoardV4Board = ({ onDesc }: { onDesc: () => void }) => {
  const [type, setType] = useState<'all' | 'school'>('school');
  return (<>
    <div className="modal-leader-board-list">
      <ScrollList type={type} onDesc={onDesc} />
    </div>
    <div className="modal-leader-board-kaka-1"></div>
    <div className="modal-title-row">
      <div className="title">排行榜</div>
      <div className="modal-title" onClick={() => setType('all')}>
        <div className="modal-title-l"></div>
        <div className="modal-title-c">全港</div>
        <div className="modal-title-r"></div>
      </div>
      <div className="modal-title modal-title-school" onClick={() => setType('school')}>
        <div className="modal-title-l"></div>
        <div className="modal-title-c">校內</div>
        <div className="modal-title-r"></div>
      </div>
    </div>
    <a className="modal-leader-board-btn-desc" onClick={onDesc}>計算及規則</a>
  </>)
}

const ModalLeaderBoardV4Description = ({ onClose }: { onClose: () => void }) => {
  return (<>
    <ScrollbarVertical>
      <h1>排行榜積分計算及規則︰</h1>
      <h2>積分計算方法</h2>
      <p>每個平台帳戶的積分來自「自學課題」、「喜閱歷史」與「歷史擂台」三個部分：</p>
      <ol>
        <li>「自學課題」︰每個課題最多可取得7顆星星，每取得一顆星星會獲得10分。</li>
        <li>「喜閱歷史」︰完成喜閱歷史文章的問答題以獲得積分，以回答正確題數最多的一次計算積分，每個正確回答可獲得1分，每篇文章可獲得的最高積分上限視乎題目數量而定。</li>
        <li>「歷史擂台」︰歷史擂台設有12個回合，每次可挑戰30條題目，每個正確回答可獲得1分。而每個回合將以最高得分的一次計算，即每個回合最多可獲得30分。</li>
      </ol>
      <p><b>*以上積分及權重計算會按新增內容而有所更改。</b></p>
      <h2>排行榜規則</h2>
      <p>排行榜只適用於中一至中三級別帳戶，並按帳戶積分給予以下等級名銜：</p>
      <p>狀元1401或以上、榜眼1201-1400、探花1001-1200、進士801-1000</p>
      <p>排行榜顯示原則：</p>
      <p>按積分由高至低排列，擁有相同積分的帳戶將會隨機顯示排名。</p>
      <p><b>* 平台保留修改、變更及活動內容等最終決定權，如有任何爭議，一概以平台的決定為準。</b></p>
    </ScrollbarVertical>
    <a className="modal-leader-board-btn-desc" onClick={onClose}>返回</a>
  </>)
}

const ModalLeaderBoardV4 = ({ onClose }: ModalLeaderBoardV4Props) => {
  const [isDesc, setIsDesc] = useState<boolean>(false);

  return (
    <ModalReel className="modal-leader-board" open={true} onClose={onClose}>
      {isDesc ? <ModalLeaderBoardV4Description onClose={() => setIsDesc(false)} /> : <ModalLeaderBoardV4Board onDesc={() => setIsDesc(true)} />}
    </ModalReel>)
}

export default ModalLeaderBoardV4;
